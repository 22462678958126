import React, { lazy } from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
// import DoctorCategory from "./views/DoctorCategory/DoctorCategory";
import AccountPage from "./views/Account/AccountPage";
import PatientPage from "./views/PatientList/PatientList";
import CreatePatient from "./views/CreatePatient/CreatePatient";
import EditPatient from "./views/CreatePatient/EditPatient";
import Dashboard from "./views/Dashboard/Dashboard";
import CreateAppointment from "./views/BookAppointment/CreateAppointment/CreateAppointment";
import Profile from "./views/UserProfile/UserProfile";
import ChangePassword from "./views/UserProfile/ChangePassword";
import PromocodeTab from "./views/PromoCode/PromocodeTab";
import CreatePromocode from "./views/PromoCode/CreatePromocode";
import EditPromocode from "./views/PromoCode/EditPromocode";
import BookAppointmentPage from "./views/BookAppointmentPage";
import PatientPageSwitch from "./views/PatientPageNew";
import AppointmentList from "./views/AppointmentList/AppointmentList";
import WebcamComponent from "./components/Webcam/Webcam";
import RaiseIssue from "./components/RaiseIssue/RaiseIssue";
import ChatSupport from "./views/ChatSupport/ChatSupportContainer";
import AccountingDetail from "./views/AccountingDetail/AccountingDetail";
// import ChatSupport from "./components/se";
import Camp from "./views/Camp/CampDetail";
import VonageVideoCall from "./views/VonageVideoCall/VonageVideoCall";

// import ChatSupport from "./views/Notification/Notification";

function WorkingRoutes(props) {
  const history = useHistory();
  return (
    <Switch>
      <Route exact path="/account/change_password">
        <ChangePassword />
      </Route>
      <Route path="/patient_page/:patientId">
        <PatientPageSwitch />
      </Route>
      <Route path="/account/profile">
        <Profile />
      </Route>
      <Route path="/account/account_detail">
        <AccountingDetail />
      </Route>
      <Route exact path="/account/promocode">
        <PromocodeTab />
      </Route>
      <Route history={history} exact path="/account/camp">
        <Camp />
      </Route>
      <Route exact path="/account/chat_support">
        <ChatSupport />
      </Route>
      <Route exact path="/account/raise_issue">
        <RaiseIssue />
      </Route>
      <Route path="/account">
        <AccountPage
          msoMode={props.msoMode}
          onModeChange={props.onModeChange}
        />
      </Route>
      <Route path="/patient_list">
        <PatientPage />
      </Route>
      <Route path="/book_appointment_list">
        <AppointmentList />
      </Route>
      <Route path="/book_appointment">
        <BookAppointmentPage />
      </Route>
      <Route path="/vonage">
        <VonageVideoCall />
      </Route>
      <Route path="/create_appointment/:id">
        <CreateAppointment history={history} />
      </Route>
      <Route path="/create_patient">
        <CreatePatient history={history} location={history.location} />
      </Route>
      <Route path="/webcam">
        <WebcamComponent history={history} location={history.location} />
      </Route>
      <Route path="/edit_patient/:id">
        <EditPatient history={history} location={history.location} />
      </Route>
      <Route path="/create_promocode">
        <CreatePromocode history={history} />
      </Route>
      <Route path="/edit_promocode/:id">
        <EditPromocode history={history} />
      </Route>
      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
}

export default withRouter(WorkingRoutes);
