import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";

import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardAvatar from "../../../components/Card/CardAvatar";

import { Link } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { Link as RouterLink } from "react-router-dom";
import avatar from "../../../assets/img/patient.png";
import moment from "moment";

import "react-toastify/dist/ReactToastify.min.css";
import EditIcon from "@material-ui/icons/Edit";
import BookIcon from "@material-ui/icons/Book";
import styles from "../../../assets/jss/material-dashboard-react/views/PatientListCard";
import { abbreviatedName,ageCalculator } from "../../../utils/helper";
const useStyles = makeStyles(styles);

const PatientCard = (props) => {
  const { patients, appointmentType } = props;
  const classes = useStyles();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const fab = {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  };

  const handleImage = (e) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Grid container>
      <Card
        profile
        data-testid={patients.attributes.id}
        className={classes.card}
        style={{
          marginTop: "-3px",
        }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          xs={12}
          sm={12}
          md={12}
        >
          <CardAvatar
            profile
            style={{
              // marginLeft: "31%",
              marginTop: 4,
              boxShadow: "none",
              maxWidth: 100,
              maxHeight: 110,
            }}
          >
            {patients.attributes.profile_photo !== null ? (
              <React.Fragment>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImage}
                  ref={imageUploader}
                  style={{
                    display: "none",
                  }}
                />
                <div
                  style={{
                    height: "132px",
                    //   width: "147px",
                    backgroundColor: "#489a9c",
                  }}
                >
                  {patients.attributes !== undefined ? (
                    <img
                      ref={uploadedImage}
                      src={patients.attributes.profile_photo}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "acsolute",
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </div>
              </React.Fragment>
            ) : (
              <img src={avatar} alt="..." />
            )}
          </CardAvatar>
        </Grid>
        <CardBody style={{ marginTop: "-7%" }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
          >
            <h6
              className={classes.cardCategory}
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: 16,
                color: "#334d56",
              }}
            >
              {abbreviatedName(patients.attributes.name)}(
              <span style={{ fontWeight: 500, fontSize: 13 }}>
                {ageCalculator(patients.attributes.dob)}/ {patients.attributes.gender[0].toUpperCase()}
              </span>
              )
            </h6>
          </Grid>
          {/* <br /> */}
          <Grid container spacing={0}>
            <Grid
              container
              justify="center"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={12}
            >
              <div className={classes.stats}>
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 400,
                    color: "rgb(23 20 20)",
                    fontSize: 13,
                  }}
                >
                  Registered:
                </span>
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 400,
                    color: "#4c5f6d",
                    fontSize: 13,
                  }}
                >
                  {moment(patients.attributes.created_at).format("D/MM/YYYY")}
                </span>
              </div>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={12}
            >
              <div className={classes.stats}>
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 400,
                    color: "rgb(23 20 20)",
                    fontSize: 13,
                  }}
                >
                  Last Visit:
                </span>
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 400,
                    color: "#4c5f6d",
                    fontSize: 13,
                  }}
                >
                  {patients.attributes.last_visit_datetime
                    ? 
                      moment.utc(patients.attributes.last_visit_datetime)
                        .local()
                        .format("DD/MM/YYYY")
                    : ""}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {appointmentType ? (
                <Button
                  data-testid={`selectpatient-button-${patients.attributes.name.toLowerCase()}`}
                  onClick={() => props.updatePatientId(patients.attributes.id)}
                >
                  Select Patient
                </Button>
              ) : (
                <Fragment>
                  {patients.attributes.last_visit_id !== null && (
                    <Link
                      color="inherit"
                      component={RouterLink}
                      data-testid={`patient-view-link-${patients.attributes.name.toLowerCase()}`}
                      to={`/patient_page/${patients.attributes.id}`}
                      variant="h6"
                    >
                      <Tooltip title="View" aria-label="View">
                        <Fab style={fab}>
                          <VisibilityIcon />
                        </Fab>
                      </Tooltip>
                    </Link>
                  )}
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/edit_patient/${patients.attributes.id}`}
                    data-testid={`patient-edit-link-${patients.attributes.name.toLowerCase()}`}
                    variant="h6"
                  >
                    <Tooltip title="Edit" aria-label="Edit">
                      <Fab style={fab}>
                        <EditIcon />
                      </Fab>
                    </Tooltip>
                  </Link>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    // changed to accomodate the routing better
                    to={`/book_appointment/${patients.attributes.id}?type=specific`}
                    data-testid={`patient-book-appointment-link-${patients.attributes.name.toLowerCase()}`}
                    variant="h6"
                  >
                    <Tooltip
                      title="Book Appointment "
                      aria-label="Book Appointment "
                    >
                      <Fab style={fab}>
                        <BookIcon />
                      </Fab>
                    </Tooltip>
                  </Link>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </Grid>
  );
};

PatientCard.propTypes = {
  className: PropTypes.string,
  patients: PropTypes.object.isRequired,
};

export default PatientCard;
