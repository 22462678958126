import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import { Link as RouterLink, useParams, withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import "../../../components/toast.css";
// core components
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getRequest, postRequest } from "../../../api/Api";
import axios from "../../../utils/axios1";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import CancleAppointmentAlert from "../CancelVisitAlert/CancelVisitAlert";
import { encodeGetParams } from "../../../utils/encodeParams";
import { getQueryRequest } from "../../../api/QueryRequest";
import { patientArrived,blockButton ,convertTimeIntoMiliseconds} from "../../../utils/helper";
import  { slackDebugger } from '../../../utils/slackDebbuger'

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PromocodeCard from "./PromocodeCard";
const styles = (theme) => ({
  root: {
    // padding: "16px",
    // flexGrow: 1,
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: "10px 15px",
    marginBottom: "2px",
    fontSize: "14px",
  },
});
const useStyles = makeStyles(styles);

const CreateAppointment = (props) => {
  const { control, handleSubmit, errors, setValue,watch } = useForm();
  let {
    patientId,
    appointmentType,
    doctorId,
    doctorInfo,
    doctorConsultationLanguage,
    user
  } = props;
  const [patient, setPatient] = useState([]);
  const [msoPromo, setMsoPromo] = useState([]);
  const [IsPregnantCheck, setIsPregnant] = useState();
  const [promoCodeId, setPromoCodeId] = useState([]);
  const [paymentData, setPaymentData] = useState();
  const [campInfo, setCampInfo] = useState([]);
  const [doctor, setDoc] = useState([]);
  const [doctorFee, setDoctorFee] = useState(doctorInfo.fee);
  const [adminPromocode, setAdminPromocode] = useState([]);
  const [adminPromoValue, setAdminPromoValue] = useState();
  const [msoPromoValue, setMsoPromoValue] = useState();
  const [doctorPromoValue, setDoctorPromoValue] = useState();
  const [isShowPromoCodes, setIsShowPromoCodes] = useState(true);
  const [doctorPromocode, setDoctorPromocode] = useState([]);
  const [
    isShowOtherPromocodesThanCamp,
    setIsShowOtherPromocodesThanCamp,
  ] = useState(true);

  const [
    msgToShowIfNoSlotesAfterFilter,
    setMsgToShowIfNoSlotesAfterFilter,
  ] = useState("");
  const [cancelallertMessage, setCancelallertMessage] = useState("");
  const [cancleAppointmentAlert, setCancleAppointmentAlert] = useState(false);
  const [redirectAfterAccept, setRedirectAfterAccept] = useState(false);
  const [patientVisitData, setPatientVisitData] = useState(null);
  const [isFollowUpCase, setIsFollowUpCase] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [msoTransaction, setMsoTransaction] = useState([]);
  const [patientArival, setPatientArival] = useState("");
  const [dataToSend, setDataToSend] = useState();
  const [selectedCampId,setSelectedCampId] = useState(null)
  const [isCurrentTime,setIsCurrentTime] = useState(false)


  let date = moment(
    new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "")
  ).format("YYYY-MM-DD");

  const [appointmentDate, setAppointmentDate] = useState(moment(date).toDate());
  const [appointmentTime, setAppointmentTime] = useState(null);

  const isPregnant = watch('is_pregnant')
  const [lmpDate, setLmpDate] = useState("");


  // Move from passing id to object to make the app faster

  const fetchTimelineData = (appointmentDate,appointmentTime) => {
    const endPoint = `get_patient_timelines?patient_id=${patientId}`;
    const token = "Bearer " + localStorage.getItem("jwt");
    let param = [];
    getRequest(endPoint, token, param).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        let desiredVisit 
        if (doctorInfo && data) {
           desiredVisit = data.find(
            (pick) => pick.doctor_id === doctorInfo.id
          );
          setPatientVisitData(desiredVisit);
        }
          let checkFollowUp = checkFollowUpCaseHandler(
            moment(appointmentDate).format("YYYY-MM-DD"),
            desiredVisit,
            appointmentType
          );
          if(!checkFollowUp){
            checkPromoCodeWithDateTime(appointmentTime)

          }      
      }
    });
  };

  async function handleDoctorAvailableSlots(params) {
    let queryString = encodeGetParams(params);
    getQueryRequest("get_doctor_available_slots", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
        alert(data.error);
      } else {
        // setSlots(data);
        let filteredTimeSlots = getTimeStops(data, params);
        if (filteredTimeSlots.length === 0) {
          setMsgToShowIfNoSlotesAfterFilter(
            "No available slots for this day for the doctor"
          );
        } else {
          setMsgToShowIfNoSlotesAfterFilter("");
        }
        setTimeSlots(filteredTimeSlots);
        setValue('appointment_time',filteredTimeSlots[0] || "")
      }
    });
  }
  const handleCancelAppointmentAlertOpen = (message, data,currentTimeChecker) => {
    setCancelallertMessage(message);
    setCancleAppointmentAlert(true);
    setDataToSend(data);
    setIsCurrentTime(currentTimeChecker)
  };

  const handleCancelAppointmentAlertClose = () => {
    setCancleAppointmentAlert(false);
  };

  const fetchMsoPromo = (msoData) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest(
      `get_approved_promo_code?appointment_datetime=${msoData.appointment_datetime}`,
      token
    ).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        let promocode = [];
        promocode.push(data.data);
        setMsoPromo(promocode);
      }
    });
  };

  const fetchDoctorPromoCode = (doctorData) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest(
      `get_approved_promo_code?user_id=${doctorData.doctorId}&appointment_datetime=${doctorData.appointment_datetime}`,
      token
    ).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        let doctorPromocode = [];
        doctorPromocode.push(data.data);
        setDoctorPromocode(doctorPromocode);
      }
    });
  };

  const fetchCamp = (campData) => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest(
      `get_mso_camp?appointment_datetime=${campData.appointment_datetime}`,
      token
    ).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setCampInfo(data.data);
        let checkIfDoctorLieInCamp;
        if (data.data.length !== 0) {
          checkIfDoctorLieInCamp = data.data[0].attributes.camp_doctor_ids.includes(
            doctorId
          );
        }
        if (checkIfDoctorLieInCamp) {
          setIsShowOtherPromocodesThanCamp(false);
        }
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchPatientById = () => {
      const endPoint = "patients";
      const token = "Bearer " + localStorage.getItem("jwt");
      let param = [patientId];
      getRequest(endPoint, token, param).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setPatient(data.data);
          }
        }
      });
    };
    
    const fetchDoctorDetail = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      let params = [doctorId];
      getRequest("users", token, params).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            // setDoc(data.data);
            setDoc({ ...data.data.attributes });
          }
        }
      });
    };
    const fetchMsoTransaction = () => {
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("mso_transactions", token).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          if (isMounted) {
            setMsoTransaction(data.data.data);
          }
        }
      });
    };
    fetchMsoTransaction();
    fetchPatientById();
    if (doctorId) {
      fetchDoctorDetail();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchAdminPromoCode = (adminData) => {
    console.log(adminData);
    const token = "Bearer " + localStorage.getItem("jwt");
    let endPoint = `get_admin_promo_codes?patient_id=${adminData.patientId}&doctor_id=${adminData.doctorId}&appointment_datetime=${adminData.appointment_datetime}`
    getRequest(endPoint, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setAdminPromocode(data.data);
      }
    });
  };
  const fetchPreLogOffPromocode = (adminData) => {
    fetchAdminPromoCode(adminData);
  };

  useEffect(() => {
    if (appointmentType === "individual") {
      handleSlotsRequest(doctorId, appointmentType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorId, appointmentType]);

  useEffect(()=>{
    if(timeSlots.length !== 0){
    fetchTimelineData(appointmentDate,timeSlots[0])
    }

  },[timeSlots])


  useEffect(() => {
    setTimeout(() => {
      if (patient.attributes !== undefined) {
        setValue("name", patient.attributes.name || "");
      }
      if (patient.attributes !== undefined) {
        if (patient.attributes.gender === "male") {
          setIsPregnant(false);
        } else {
          setIsPregnant(true);
        }
      }
    });
  }, [patient, setValue]);

  const classes = useStyles();

  const getPatientArrival = (value) => {
    setPatientArival(value);
  };

  const getTimeStops = (timmm)=>{
    let timeStops = [];
    let startTime,endTime
    for (let i = 0; i < timmm.length; i++) {
    for(let j = 0 ; j<timmm[i].splitted_slots.length; j++){
      startTime = timmm[i].splitted_slots[j].start_slot
      endTime = timmm[i].splitted_slots[j].end_slot
      timeStops.push({startSlot: moment.utc(startTime)
        .local()
        .format("HH:mm"), 
        endSlot:moment.utc(endTime)
          .local()
          .format("HH:mm")});
    }
  }
    return timeStops
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        onClick={props.onClick}
        value={props.value}
        data_cy ={props.tag}
        className={classes.input}
        type="text"
        placeholder="Select Date"
        readOnly={true}
      />
    );
  });
  var promoCodeidsArray = [];

  const handleSlotsRequest = (value, type) => {
    const params = {
      // type: type,
      id: value,
      apt_date: moment(appointmentDate).format("YYYY-MM-DD"),
    };
    handleDoctorAvailableSlots(params);
  };

  const applyPromoCodeHandler = (
    doctor_id,
    patient_appointment
  ) => {
    if (
      (patientArival === "" || patientArival === 0) &&
      checkDoctorLieInCampHandler(doctorId)
    ) {
      alert("Please select patient arrival status as Arrived");
      setAdminPromoValue();
      return false;
    }
    patient_appointment.appointment_datetime = createTimeIstTimeStamp(
      appointmentDate,
      appointmentTime
    );
    patient_appointment.is_patient_arrived = patientArival;
    console.log(patient_appointment);
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      if (doctor_id !== "") {
        axios
          .post(
            "/apply_promo_code",
            { appointment: patient_appointment },
            { headers: { Authorization: token } }
          )
          .then((response) => {
            setPaymentData(response.data.data);
          })
          .catch((error) => {
            setAdminPromoValue();
            setValue("admin_promo", "" || "");
            setValue("msp_promo", "" || "");
            setValue("doctor_promo", "" || "");
            setMsoPromoValue()
            setDoctorPromoValue();
            setPaymentData();
            if (error.response.data !== "") {
              alert(error.response.data.error);
            } else {
              alert(error.response.statusText);
            }
          });
      }
    }
  };

  const checkDoctorLieInCampHandler = (doctorId,campId) => {
    let idCamp = null;
    let checkIfDoctorLieInCamp = null
    if (campInfo.length !== 0) {
      for(let m = 0; m<campInfo.length;m++){
       checkIfDoctorLieInCamp = campInfo[m].attributes.camp_doctor_ids.find((el)=>{
         return parseInt(el) === parseInt(doctorId)
       })
       if(checkIfDoctorLieInCamp) break;
      }
      idCamp = checkIfDoctorLieInCamp ? campId : null;
      return idCamp;
    } else {
      return idCamp;
    }
  };

  const removePromoCodeHandler = (id, identifier) => {
    let doctor_id = appointmentType === "individual" ? doctorId : " ";
    let PromocodeArrayAfterRemove = [];
    if (identifier === "a") {
      setAdminPromoValue();
      setSelectedCampId()
      const newPromoArray = promoCodeId.filter((el) => {
        return el !== id.id;
      });
      setPromoCodeId(newPromoArray);
      PromocodeArrayAfterRemove.push(newPromoArray);
    } else if (identifier === "m") {
      setMsoPromoValue();
      const newPromoArray = promoCodeId.filter((el) => {
        return el !== id.id;
      });
      setPromoCodeId(newPromoArray);
      PromocodeArrayAfterRemove.push(newPromoArray);
    } else if (identifier === "d") {
      setDoctorPromoValue();
      const newPromoArray = promoCodeId.filter((el) => {
        return el !== id.id;
      });
      setPromoCodeId(newPromoArray);
      PromocodeArrayAfterRemove.push(newPromoArray);
    }
    let idOfCamp =  null;
    const patient_appointment = {
      patient_id: patientId,
      doctor_id: doctor_id,
      camp_id:
        id.info.attributes.promo_code_category === "camp" ? idOfCamp : null,
      promo_code_id: PromocodeArrayAfterRemove[0],
    };
    applyPromoCodeHandler(doctor_id, patient_appointment);
  };

  let adminPromo;
  let msoPromocode;
  let doctorPromo;
  const GetPromoInfo = (id, identifier) => {
    let campId = null
    if (identifier === "a") {
      campId = id.attributes.camp.length !== 0 ? id.attributes.camp[0].id : null
      setSelectedCampId(campId)
      adminPromo = id.attributes.id;
      setAdminPromoValue({ id: adminPromo, info: id });
      msoPromocode = msoPromoValue?.id;
      doctorPromo = doctorPromoValue?.id;
    } else if (identifier === "m") {
      msoPromocode = id.attributes.id;
      setMsoPromoValue({ id: msoPromocode, info: id });
      adminPromo = adminPromoValue?.id;
      doctorPromo = doctorPromoValue?.id;
    } else if (identifier === "d") {
      doctorPromo = id.attributes.id;
      setDoctorPromoValue({ id: doctorPromo, info: id });
      adminPromo = adminPromoValue?.id;
      msoPromocode = msoPromoValue?.id;
    }
    if (adminPromo) {
      promoCodeidsArray.push(adminPromo);
    }
    if (msoPromocode) {
      promoCodeidsArray.push(msoPromocode);
    }
    if (doctorPromo) {
      promoCodeidsArray.push(doctorPromo);
    }
    setPromoCodeId(promoCodeidsArray);
    let doctor_id;
    if (appointmentType === "individual") {
      doctor_id = doctorId;
    } else {
      doctor_id = "";
    }
    let idOfCamp = doctor_id ? checkDoctorLieInCampHandler(doctor_id,campId) : null;
    const patient_appointment = {
      patient_id: patientId,
      doctor_id: doctor_id,
      camp_id: id.attributes.promo_code_category === "camp" ? idOfCamp : null,
      promo_code_id: promoCodeidsArray,
    };
    applyPromoCodeHandler(doctor_id, patient_appointment);
  };

  const handleAppointmentDate = (value) => {
    setMsoPromo([]);
    setPaymentData();
    setAdminPromoValue();
    setMsoPromoValue();
    setDoctorPromoValue();
    setAdminPromocode([]);
    setDoctorPromocode([]);
    setPromoCodeId([]);
    setAppointmentDate(value);
    setTimeSlots([]);

    if (value !== null) {
      if (
        appointmentType === "individual" ||
        appointmentType === "speciality"
      ) {
        const params = {
          // type: "individual",
          id: doctorId,
          apt_date: moment(value).format("YYYY-MM-DD"),
        };
        handleDoctorAvailableSlots(params);
      } 
    }
  };

  const handleRedirection = () => {
    setRedirectAfterAccept(true);
  };

  const checkFollowUpCaseHandler = (date, patientVisit) => {
      if (patientVisit) {
        let lastVisitDate =  moment.utc(patientVisit?.visit_datetime)
                            .local()
                            .format("DD MMM YYYY")
       
        console.log(lastVisitDate);
        let sumOfLastvistAndFollowUpAt = moment(lastVisitDate)
          .add(doctorInfo.followUpAt, "days")
          .format("YYYY-MM-DD");
        if (
          new Date(date).getTime() <=
            new Date(sumOfLastvistAndFollowUpAt).getTime() &&
          patientVisit.doctor_id === doctorInfo.id &&
          patientVisit
        ) {
          setDoctorFee(`${0} (Follow-up)`);
          setIsShowPromoCodes(false);
          setIsFollowUpCase(true)
          return true;
        } else {
          setDoctorFee(doctorInfo.fee);
          // referralDiscountCase(appointmentDesiredDatetime)
          setIsShowPromoCodes(true);
          setIsFollowUpCase(false)
          return false;
        }
    } 
  };

  const createTimeIstTimeStamp = (appointmentDate, appointmentTime) => {
    let dateOfAppointment = moment(appointmentDate).format("YYYY-MM-DD");
    dateOfAppointment += " " + appointmentTime;
    const newDate = new Date(dateOfAppointment).toISOString();
    return newDate;
  };

  const referralDiscountCase = (appointmentDesiredDatetime)=>{
    let form = new FormData();
    form.append("appointment[patient_id]", patientId);
    form.append("appointment[doctor_id]", doctorId);
    form.append(
      "appointment[appointment_datetime]",
      appointmentDesiredDatetime
    );
    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "get_referral_discount",
      formData: form,
    };
    postRequest(body).then((data) => {
      if (data.error) {
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        setPaymentData(data.data)
        // setDoctorFee(data.data.payment_received)
    }
    });
  }

  const checkCurrentTimeLieInSlotRange = (slotRange)=>{
    const startSlot = Number(slotRange.startSlot.split(":")[0]) * 60 + Number(slotRange.startSlot.split(":")[1]);
    const endSlot =  Number(slotRange.endSlot.split(":")[0]) * 60 + Number(slotRange.endSlot.split(":")[1]);;
    const date = new Date(); 
    const now = date.getHours() * 60 + date.getMinutes();
   
    if(startSlot <= now && now <= endSlot){
      return true
    }else{
      return false
    }
  }

  const checkPromoCodeWithDateTime = (selectedTime) => {
    let selectedAppointmentTime;
    const currentTimeChecker = checkCurrentTimeLieInSlotRange(selectedTime)
    const presentTime = moment().format("HH:mm");
    if(currentTimeChecker){
      if(convertTimeIntoMiliseconds(selectedTime.endSlot) > convertTimeIntoMiliseconds(presentTime)){
        selectedAppointmentTime = presentTime
      }
    }else{
      selectedAppointmentTime = selectedTime.startSlot
    }
    setAppointmentTime(selectedAppointmentTime);
    setMsoPromo([]);
    setPaymentData();
    setAdminPromoValue();
    setMsoPromoValue();
    setDoctorPromoValue();
    setAdminPromocode([]);
    setDoctorPromocode([]);
    setPromoCodeId([]);
    let appointmentDateTimeIso = createTimeIstTimeStamp(
      appointmentDate,
      selectedAppointmentTime
    )
    
    let promoCodeValidityCheck = {
      patientId: patientId,
      doctorId: doctorInfo?.id,
      appointment_datetime: appointmentDateTimeIso
    };
    const checkFollow = checkFollowUpCaseHandler(
      moment(appointmentDate).format("YYYY-MM-DD"),
      patientVisitData,
    );
    if(!checkFollow && appointmentType === "individual"){
      referralDiscountCase(appointmentDateTimeIso)
    }
      fetchCamp(promoCodeValidityCheck);
      fetchPreLogOffPromocode(promoCodeValidityCheck);
      fetchMsoPromo(promoCodeValidityCheck);
      if (doctorId) {
        fetchDoctorPromoCode(promoCodeValidityCheck);
      }
  };

  const onSubmit = (data) => {
    if (patient.attributes.gender !== undefined) {
      if (patient.attributes.gender === "male") {
        data.is_pregnant = 0;
      }
    }

    if (data.is_pregnant === false) {
      data.is_pregnant = 0;
    }
    if (data.is_pregnant === true) {
      data.is_pregnant = 1;
    }
    const date = moment(appointmentDate).format("YYYY-MM-DD");
    if (data.is_patient_arrived === 1) {
      var d1 = new Date();
      if (new Date(d1).getTime() >= new Date(date).getTime()) {
        console.log("");
      } else {
        alert("Patient has arrived. Appointment Date is not correct");
        return false;
      }
    }
    var payment;
    if (paymentData !== undefined) {
      payment = paymentData.payment_received;
    } else {
        payment = doctorInfo.fee;
    }
    if (patientVisitData && appointmentType !== "category") {
      let lastVisitDate = moment.utc(patientVisitData?.visit_datetime)
                          .local()
                          .format("DD MMM YYYY");
      console.log(lastVisitDate);
      let sumOfLastvistAndFollowUpAt = moment(lastVisitDate)
        .add(doctor.follow_up_time, "days")
        .format("YYYY-MM-DD");
      if (
        new Date(date).getTime() <=
          new Date(sumOfLastvistAndFollowUpAt).getTime() &&
        patientVisitData.doctor_id === doctorInfo.id
      ) {
        payment = 0;
      }
    }

    let doctor_id;
    doctor_id = doctorId;
    blockButton('appointmentButton',true);
    var formData = new FormData();
    formData.append("appointment[patient_id]", patientId);
    formData.append("appointment[doctor_type]", appointmentType);
    formData.append("appointment[doctor_id]", doctor_id);
    formData.append("appointment[fee]", doctorInfo.fee);
    formData.append("appointment[consulting_language]", doctorConsultationLanguage);
    formData.append("appointment[is_pregnant]", data.is_pregnant);
    if(!data.is_pregnant && lmpDate){
      formData.append("appointment[lmp]", moment(lmpDate).format("YYYY-MM-DD"));
    }
    let selectedAppTime;
    const currentTimeChecker = checkCurrentTimeLieInSlotRange(data.appointment_time)
    const presentTime = moment().format("HH:mm");
    if(currentTimeChecker){
      if(convertTimeIntoMiliseconds(data.appointment_time.endSlot) > convertTimeIntoMiliseconds(presentTime)){
        selectedAppTime = presentTime
      }
    }else{
      selectedAppTime = data.appointment_time.startSlot
    }
    formData.append(
      "appointment[appointment_datetime]",
      createTimeIstTimeStamp(appointmentDate, selectedAppTime)
    );

    formData.append("appointment[payment_received]", payment);
    for (let i = 0; i < promoCodeId.length; i++) {
      formData.append("appointment[promo_code_id][]", promoCodeId[i]);
    }
    formData.append(
      "appointment[is_patient_arrived]",
      data.is_patient_arrived
    );
    if (selectedCampId) {
      formData.append("appointment[camp_id]", selectedCampId);
    }


    let token = "Bearer " + localStorage.getItem("jwt");
    const body = {
      token: token,
      endPoint: "appointments",
      formData: formData,
    };

    postRequest(body).then((data) => {
      if (data.error) {
        blockButton('appointmentButton',false);
        if (!data.hasOwnProperty("pending_visits")) {
          toast.error(<p>{data.error}</p>, { autoClose: 3000 });
        }
        if (data.hasOwnProperty("pending_visits") && data.pending_visits > 0) {
          handleCancelAppointmentAlertOpen(data.error, body,currentTimeChecker);
        }
      } else {
        if(user.attributes.centre.current_credit_balance <= 499){
          slackDebugger({
            actionType:"balanceChecking",
            centreName:user.attributes.centre?.centre_title,
            msoNumber:user.attributes.mobile,
            oldBalance:msoTransaction[1].attributes.balance_after_transaction,
            storeCode:user.attributes.centre?.store_code,
            balance:user.attributes.centre.current_credit_balance
          })
        }
        toast(<p>{data.message}</p>, {
          className: "custom",
          autoClose: 2000,
        });
        setTimeout(() => {
          props.history.push("/book_appointment_list");
        }, 2000);
      }
    });
  };

  const appointmentForm = () => {
    return (
      <div className={classes.root} style={{ marginTop: "3px" }}>
        {redirectAfterAccept === true ? (
          <Redirect to="/book_appointment_list" />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card data-testid="book-appointment-card">
              <CardHeader
                style={{ width: "140px", padding: "6px" }}
                color="success"
              >
                <h4 className={classes.cardTitleWhite}>Create Appointment</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Controller
                        as={<TextField />}
                        error={Boolean(errors.name)}
                        name="name"
                        rules={{ required: "Patient Name is required" }}
                        control={control}
                        defaultValue=""
                        InputProps={{
                          readOnly: true,
                        }}
                        inputProps={{
                          data_cy :`patient-name-for-appointment-input`
                        }}
                        label="Patient Name"
                        type="text"
                        helperText={errors.name && errors.name.message}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                        {appointmentType === "individual"
                          ? "Doctor Name"
                          : "Category"}
                      </Typography>
                      <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                        {doctorInfo.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl
                        style={{ minWidth: 170 }}
                        error={Boolean(errors.is_patient_arrived)}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Patient Arrived Status
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              {patientArrived.map((option) => (
                                <MenuItem data_cy ={`patient-arrival-status-for-appointment-option-${option.key}`}
                                key={option.key} value={option.key}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                          name="is_patient_arrived"
                          rules={{
                            required: "Patient Arrived Status is required",
                          }}
                          inputProps={{
                            data_cy :`patient-arrived-status-for-appointment-dropdown`
                          }}
                          control={control}
                          // helperText={errors.is_patient_arrived && errors.is_patient_arrived.message}
                          defaultValue=""
                          onChange={([selected]) => {
                            getPatientArrival(selected.target.value);
                            return selected;
                          }}
                        />
                        <FormHelperText>
                          {errors.is_patient_arrived &&
                            errors.is_patient_arrived.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      container
                      direction="column"
                    >
                      <label>Date of Appointment</label>
                      <ReactDatePicker
                        error={Boolean(errors.appointment_date)}
                        valueName="selected"
                        onChange={(date) => handleAppointmentDate(date)}
                        name="appointment_date"
                        selected={appointmentDate}
                        rules={{ required: "Appointment Date is required" }}
                        dateFormat="yyyy-MM-dd"
                        helperText={
                          errors.appointment_date &&
                          errors.appointment_date.message
                        }
                        minDate={moment().toDate()}
                        isClearable
                        customInput={<CustomInput tag={`patient-appointment-datepicker`} />}
                        popperPlacement="bottom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                          },
                        }}
                      />
                      {errors.appointment_date && (
                        <div style={{ color: "red" }}>
                          {" "}
                          Appointment Date is required
                        </div>
                      )}
                    </Grid>
                    {timeSlots.length !== 0 &&
                    msgToShowIfNoSlotesAfterFilter === "" ? (
                      <Grid item xs={12} container sm={4} md={4}>
                        <FormControl
                          style={{ minWidth: 180 }}
                          error={Boolean(errors.appointment_time)}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Appointment Time
                          </InputLabel>

                          <Controller
                            as={
                              <Select>
                                {timeSlots.map((opt,index) => (
                                  <MenuItem
                                  data_cy = {`patient-appointment-timeslots-option-${opt.startSlot}`}
                                  key={opt.startSlot} value={opt}>
                                    {opt.startSlot}-{opt.endSlot} 
                                  </MenuItem>
                                  ))}
                              </Select>
                            }
                            name="appointment_time"
                            rules={{
                              required: "Appointment Time is required",
                            }}
                            inputProps={{
                              data_cy :`patient-appointment-time-dropdown`
                            }}
                            control={control}
                            onChange={([selected]) => {
                              checkPromoCodeWithDateTime(selected.target.value);
                              return selected;
                            }}
                            defaultValue=""
                          />
                          <FormHelperText>
                            {errors.appointment_time &&
                              errors.appointment_time.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        {msgToShowIfNoSlotesAfterFilter !== "" && (
                          <Alert severity="info">
                            <AlertTitle>Oops</AlertTitle>
                            <strong>{msgToShowIfNoSlotesAfterFilter}</strong>
                          </Alert>
                        )}
                      </React.Fragment>
                    )}
                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={4}
                      md={4}
                    >
                      <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                        Fee
                      </Typography>
                      <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                        ₹ {doctorFee}
                      </Typography>
                    </Grid>
                    {isShowPromoCodes && (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                            Promo Codes
                          </Typography>
                        </Grid>
                        {adminPromocode.length !== 0 && (
                          <Grid item xs={6} container sm={4} md={4}>
                            {!adminPromoValue ? (
                              <FormControl style={{ width: 156 }}>
                                <InputLabel id="demo-simple-select-label">
                                  Admin Promo codes
                                </InputLabel>

                                <Controller
                                  as={
                                    <Select>
                                      {adminPromocode.map((option) => (
                                        <MenuItem
                                        data_cy = {`admin-promocode-for-appointment-option-${option.attributes.title}`}
                                          key={option.attributes.id}
                                          value={option}
                                        >
                                          {option.attributes.title}
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              marginLeft: "5%",
                                            }}
                                          >
                                            ({option.attributes.discount} %)
                                          </span>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  }
                                  inputProps={{
                                    data_cy :`admin-promocode-for-appointment-dropdown`
                                  }}
                                  name="admin_promo"
                                  onChange={([selected]) => {
                                    GetPromoInfo(
                                      selected.target.value,
                                      "a",
                                      selected.target.key
                                    );
                                    return selected;
                                  }}
                                  control={control}
                                  defaultValue=""
                                />
                              </FormControl>
                            ) : (
                              <PromocodeCard
                                removePromoCodeHandler={removePromoCodeHandler}
                                id={adminPromoValue}
                                identifier="a"
                              />
                            )}
                          </Grid>
                        )}
                        {msoPromo[0] && isShowOtherPromocodesThanCamp && (
                          <Grid item xs={6} container sm={4} md={4}>
                            {!msoPromoValue ? (
                              <FormControl style={{ width: 160 }}>
                                <InputLabel id="demo-simple-select-label">
                                  Mso Promo codes
                                </InputLabel>

                                <Controller
                                  as={
                                    <Select>
                                      {msoPromo.map((option) => (
                                        <MenuItem
                                        data_cy = {`mso-promocode-for-appointment-option-${option.attributes.title}`}
                                          key={option.attributes.id}
                                          value={option}
                                        >
                                          {option.attributes.title}
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              marginLeft: "5%",
                                            }}
                                          >
                                            ({option.attributes.discount} %)
                                          </span>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  }
                                  inputProps={{
                                    data_cy :`mso-promocode-for-appointment-dropdown`
                                  }}
                                  onChange={([selected]) => {
                                    GetPromoInfo(selected.target.value, "m");
                                    return selected;
                                  }}
                                  name="msp_promo"
                                  control={control}
                                  defaultValue=""
                                />
                              </FormControl>
                            ) : (
                              <PromocodeCard
                                removePromoCodeHandler={removePromoCodeHandler}
                                id={msoPromoValue}
                                identifier="m"
                              />
                            )}
                          </Grid>
                        )}
                        {doctorPromocode[0] && isShowOtherPromocodesThanCamp && (
                          <Grid item xs={6} container sm={4} md={4}>
                            {!doctorPromoValue ? (
                              <FormControl style={{ width: 160 }}>
                                <InputLabel id="doctor-promo">
                                  Doctor Promo codes
                                </InputLabel>

                                <Controller
                                  as={
                                    <Select>
                                      {doctorPromocode.map((option) => (
                                        <MenuItem
                                        data_cy = {`doctor-promocode-for-appointment-option-${option.attributes.title}`}
                                          key={option.attributes.id}
                                          value={option}
                                        >
                                          {option.attributes.title}
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              marginLeft: "5%",
                                            }}
                                          >
                                            ({option.attributes.discount} %)
                                          </span>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  }
                                  inputProps={{
                                    data_cy :`doctor-promocode-for-appointment-dropdown`
                                  }}
                                  onChange={([selected]) => {
                                    GetPromoInfo(selected.target.value, "d");
                                    return selected;
                                  }}
                                  name="doctor_promo"
                                  control={control}
                                  defaultValue=""
                                />
                              </FormControl>
                            ) : (
                              <PromocodeCard
                                removePromoCodeHandler={removePromoCodeHandler}
                                id={doctorPromoValue}
                                identifier="d"
                              />
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                    {IsPregnantCheck && (
                      <Grid item xs={12} sm={12} md={12}>
                        <div >
                          <label>Is Pregnant</label>
                          <Controller
                            control={control}
                            as={<Checkbox />}
                            inputProps={{
                              data_cy :`is-pregnant-check-for-appointment-checkbox`
                            }}
                            name="is_pregnant"
                            defaultValue={false}
                          />
                        </div>
                        {!isPregnant &&(
                        <div
                          style={{ display:'flex',flexDirection:'column',
                          width:174,marginTop:'1%' }}
                        >
                          <label>LMP ( Last Menstrual Period )</label>
                          <ReactDatePicker
                            valueName="selected"
                            onChange={(date) => setLmpDate(date)}
                            name="lmp"
                            selected={lmpDate}
                            dateFormat="yyyy-MM-dd"
                            isClearable
                            maxDate={moment().toDate()}
                            customInput={<CustomInput tag='lmp-datepicker' />}
                            popperPlacement="bottom-start"
                            popperModifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                              },
                            }}
                          />
                      </div>)}
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12}>
                      {paymentData && (
                        <div style={{ float: "right" }}>
                          <Typography>
                            Total Amount : Rs {paymentData.total_amount}
                          </Typography>
                          <br />
                          <Typography>
                            Discount : Rs {paymentData?.discount ?? 0}
                          </Typography>
                          <br />
                          <Typography style={{ fontWeight: "bold" }}>
                            Payment to Receive : Rs{" "}
                            {Math.max(0, paymentData.payment_received)}
                          </Typography>
                        </div>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      container
                      justify="flex-end"
                    >
                      <CardFooter>
                        <Button
                          data-testid="create-appointment-button"
                          id="appointmentButton"
                          type="submit"
                        >
                          Create
                        </Button>
                        {props.onCancel ? (
                          <Button
                            style={{
                              color: "rgb(61, 170, 153)",
                              border: "1px solid rgb(226 226 226)",
                              backgroundColor: "rgb(255 255 255)",
                              boxShadow: "none",
                              marginLeft: "3%",
                            }}
                            onClick={() => props.onCancel()}
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            component={RouterLink}
                            to="/book_appointment_list"
                            data-testid="cancel-appointment-button"
                            style={{
                              color: "rgb(61, 170, 153)",
                              border: "1px solid rgb(226 226 226)",
                              backgroundColor: "rgb(255 255 255)",
                              boxShadow: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                        <ToastContainer />
                      </CardFooter>
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
              <CancleAppointmentAlert
                onClose={handleCancelAppointmentAlertClose}
                open={cancleAppointmentAlert}
                message={cancelallertMessage}
                dataToSend={dataToSend}
                handleRedirection={handleRedirection}
                isCurrentTime={isCurrentTime}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <div>{appointmentForm()}</div>;
};

export default withRouter(CreateAppointment);

// TODO: Move this to the utils/helper file
