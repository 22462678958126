import React, { useState, useEffect,useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TablePagination, Grid, Link, Hidden } from "@material-ui/core";
import {
  Link as RouterLink,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";
import NoData from "../../assets/img/nodata.png";
import { getRequest } from "../../api/Api";
import { encodeGetParams } from "../../utils/encodeParams";
import { getQueryRequest } from "../../api/QueryRequest";
import PatientCard from "./PatientCard/PatientCard";
import Loader from "../../components/Loader/Loader";
import SearchBar from "../../components/SearchBar/SearchBar";
import PatientDetail from "../PatientPage/PatientPage";
import { ToastContainer } from "react-toastify";
import PatientMobileViewList from "./PatientMobileViewList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from 'axios';
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import baseUrl from "utils/baseUrl";
import debounce from 'lodash.debounce'

const styles = theme => ({
  root: {
    padding: "12px",
  },
  results: {},
  paginate: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  fab: {
    backgroundColor: "rgb(61, 170, 153)",
    width: 37,
    height: 30,
    margin: "2%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
});
const useStyles = makeStyles(styles);

const PatientList = props => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [patients, setPatients] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();

  const handleChangePage = (event, page) => {
    setPage(page);
    let params = {};
    params.page = page + 1;
    params.items = rowsPerPage;

    let queryString = encodeGetParams(params);
    getQueryRequest("patients", queryString).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatients(data.data.data);
      }
    });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    let params = {};
    params.page = page + 1;
    params.items = event.target.value;
    let queryString = encodeGetParams(params);
    getQueryRequest("patients", queryString).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatients(data.data.data);
      }
    });
  };

  const fetchPatient = async(name) => {
    if(name){
    let params = {}
      params.name = name
     let queryString = encodeGetParams(params);
    try {
      const token = "Bearer " + localStorage.getItem("jwt");
      const response = await axios.get(`${baseUrl}/search_patients?${queryString}`, {
        headers: { Authorization: token }
      })
      setPatients(response.data.data);       
    } catch (err) {
      console.log('There was a problem or request was cancelled.')
    }
  }
  }
  const delayedRequest = useCallback(debounce(name=>fetchPatient(name), 500), []);

  useEffect(() => {
    let isMounted = true;
    if(!search){
      setLoading(true);
      const token = "Bearer " + localStorage.getItem("jwt");
      getRequest("patients", token).then(data => {
        if (data.error) {
          console.log(data.error);
          setLoading(false);
        } else {
          if (isMounted) {
          setPatients(data.data.data);
          setTotalCount(data.total_rows);
          setLoading(false);
          }
        }
      });
    }
    return () => {
      isMounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  // useEffect(() => {
  //   if( totalCount === rowsPerPage && page > 0 ){
  //     setPage(0);
  //   }
  //    }, [totalCount, rowsPerPage, page]);

  const PaginationComponent = () => {
    return (
      <TablePagination
        component="div"
        count={parseInt(totalCount)}
        data-testid="patient-list-pagination-button"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page > 0 && totalCount === rowsPerPage ? 2 : page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    );
  };

  return (
    <div className={classes.root} style={{ marginTop: "9px" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <SearchBar datatestid="search-patient-input" onSearch={(e) => {delayedRequest(e.target.value);setSearch(e.target.value)}} />
            </Grid>
          </Grid>
          <Grid>
            <Grid item container justify="flex-end" xs={12} sm={12} md={12}>
              <Link
                color="inherit"
                component={RouterLink}
                style={{ marginBottom: "1%" }}
                to="/create_patient"
                data-testid="create-patient-link"
                variant="h6"
              >
                <Tooltip title="Create Patient" aria-label="Create Patient">
                  <Fab className={classes.fab}>
                    <AddCircleOutlineIcon />
                  </Fab>
                </Tooltip>
              </Link>
            </Grid>
            <Grid container spacing={1}>
              <Grid sx={12} sm={12} md={12} item container justify="center">
                {patients.length === 0 && (
                  <div>
                    <img style={{ width: 300 }} src={NoData} alt="nodata" />
                  </div>
                )}
              </Grid>
              <Hidden xsDown>
                {patients.map(patients => (
                  <Grid item key={patients.id} md={3} sm={3} xs={12}>
                    <PatientCard
                      key={patients.id}
                      patients={patients}
                      appointmentType={props.appointmentType}
                      updatePatientId={props.updatePatientId}
                    />
                  </Grid>
                ))}
              </Hidden>
              <Hidden smUp>
                <div>
                  <PaginationComponent />
                </div>
                <Grid item xs={12}>
                  <PatientMobileViewList
                    appointmentType={props.appointmentType}
                    updatePatientId={props.updatePatientId}
                    patients={patients}
                  />
                </Grid>
              </Hidden>
            </Grid>
            <Hidden xsDown>
              <div className={classes.paginate}>
                <PaginationComponent />
              </div>
            </Hidden>
          </Grid>
        </React.Fragment>
      )}
      <ToastContainer/>
    </div>
  );
};

function PatientPage(props) {
  let { path } = useRouteMatch();
  // console.log(doctorId);
  return (
    <Switch>
      <Route exact path={path}>
        <PatientList {...props} />
      </Route>
      {/* :id is patientId and :type is visitId */}
      <Route path={`${path}/:id/:type`}>
        <PatientDetail />
      </Route>
    </Switch>
  );
}

export default PatientPage;
